import React from 'react'
import './Loader.css';
import { BasicInfo } from '../../Config/BasicInfo';
const Loader = () => {
    return (

        <div className="myLoadingAnim">
            <div class="scene">
                <div class="cube-wrapper">
                    <div class="cube">
                        <div class="cube-faces">
                            <div class="cube-face shadow">
                                <img src={BasicInfo.logoIcon} alt="" />
                            </div>
                            <div class="cube-face bottom">
                                <img src={BasicInfo.logoIcon} alt="" />
                            </div>
                            <div class="cube-face top">
                                <img src={BasicInfo.logoIcon} alt="" />
                            </div>
                            <div class="cube-face left">
                                <img src={BasicInfo.logoIcon} alt="" />
                            </div>
                            <div class="cube-face right">
                                <img src={BasicInfo.logoIcon} alt="" />
                            </div>
                            <div class="cube-face back">
                                <img src={BasicInfo.logoIcon} alt="" />
                            </div>
                            <div class="cube-face front">
                                <img src={BasicInfo.logoIcon} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader