import Logo from "./../Assets/Images/logo.svg"
import LogoIcon from "./../Assets/Images/logoIcon.svg"
import { toast } from "react-toastify";
import smallGameBanner from './../Assets/Images/gamesmallbanner.png'
export const BasicInfo = {
    logo: Logo,
    logoIcon: LogoIcon,
    projectName: "Gamepitara",
    DefaultGameBanner: smallGameBanner,
    isDebug: true

}
export const toastSuccess = (msg) => toast.success(msg);
export const toastFailed = (msg) => toast.error(msg);