// import React, { useEffect, useState } from "react";
// import { MdOutlineFilterList } from "react-icons/md";
// import { ApiPaths } from "../../Config/ApiPath";
// import Loader from "../../Components/Loader/Loader";
// import PaginationComponent from "./../../Components/PaginationControls/PaginationControls";
// import useAxiosHelper from "../../Common/AxiosHelper";
// import { useSelector, useDispatch } from "react-redux";
// import { Container, Row, Col } from "react-bootstrap";
// import { BasicInfo } from "../../Config/BasicInfo";
// const FundHistory = ({ status }) => {
//   const [selectIncome, setSelectIncome] = useState(1);
//   const [tabsName, setTabsName] = useState("deposit");

//   const [filterVisiblity, setFilterVisiblity] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [runOnce, setRunOnce] = useState(true);
//   const [paymentTransaction, setPaymentTransaction] = useState();

//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [initValue, setInitValue] = useState(0);
//   const [totalPages, setTotalPages] = useState(0);
//   const [pageNum, setPageNum] = useState();
//   const [currentPage, setCurrentPage] = useState(1);

//   const { AxiosGet } = useAxiosHelper();
//   const incomeData = useSelector((state) => state.incomeData.incomeWallet);

//   // const handleTabChange = (tabName) => {
//   //   setTabsName(tabName);
//   //   localStorage.setItem("historyTab", tabName);
//   // };

//   const handlePagination = (page) => {
//     setCurrentPage(page);
//   };

//   var x = 0;

//   useEffect(() => {
//     if (x === 0) {
//       FetchData();
//       x++;
//     }
//   }, [status]);
//   const FetchData = async (page) => {
//     try {
//       setLoading(true);
//       const filters = {
//         tx_type: "add_fund",
//         status: status,
//       };
//       const queryParams = {
//         page: page,
//         limit: 20,

//         ...filters,
//       };
//       const response = await AxiosGet(
//         `${ApiPaths.getPaymentTransaction}?${new URLSearchParams(
//           queryParams
//         ).toString()}`
//       );
//       BasicInfo.isDebug && console.log("Fund history", response);

//       setPaymentTransaction(response?.data || []);
//       setTotalPages(response?.totalPages || 1);
//     } catch (error) {
//       console.error("Error fetching payment transactions:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   function resetData() {
//     setStartDate(" ");
//     setEndDate(" ");

//     FetchData();
//   }

//   return (
//     <>
//       {loading ? <Loader /> : null}

//       <section>
//         <div className="incomeSelectBox">
//           <button onClick={() => setFilterVisiblity(!filterVisiblity)}>
//             {filterVisiblity ? "Hide Filters" : "Show Filters"}
//             <i>
//               {" "}
//               <MdOutlineFilterList />
//             </i>{" "}
//           </button>
//         </div>
//         {filterVisiblity ? (
//           <section className="filtersection inputPrimary">
//             <Row>
//               <Col lg="2" md="4" xs="12">
//                 <input
//                   type="date"
//                   name=""
//                   id=""
//                   placeholder="Start Date"
//                   value={startDate}
//                   onChange={(e) => setStartDate(e.target.value)}
//                 />
//               </Col>
//               <Col lg="2" md="4" xs="12">
//                 <input
//                   type="date"
//                   name=""
//                   id=""
//                   placeholder="End Date"
//                   value={endDate}
//                   onChange={(e) => setEndDate(e.target.value)}
//                 />
//               </Col>
//               <Col lg="2" md="4" xs="6">
//                 <button onClick={() => FetchData()}>Search</button>
//               </Col>
//               <Col lg="2" md="4" xs="6">
//                 <button onClick={() => resetData()}>Reset</button>
//               </Col>
//             </Row>
//           </section>
//         ) : null}

//         <section className="history">
//           <h1 className="textHeading">
//             {status == 0 ? "Pending" : "Approved"} Fund Requests
//           </h1>
//           <div className="table">
//             <table>
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>Amount (INR )</th>
//                   <th>Date</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {paymentTransaction != null && Array.isArray(paymentTransaction)
//                   ? paymentTransaction?.map((x, i) => {
//                       return (
//                         <tr>
//                           <td>{i + 1}</td>
//                           <td>{parseFloat(x?.amount).toFixed(2)}</td>
//                           {/* {x?.status == "0" ? (
//                             <td>Pending</td>
//                           ) : x?.status == "1" ? (
//                             <td style={{ color: "green" }}>Success</td>
//                           ) : (
//                             <td style={{ color: "red" }}>Rejected</td>
//                           )} */}
//                           <td>{x?.updatedAt}</td>
//                         </tr>
//                       );
//                     })
//                   : ""}
//               </tbody>
//             </table>
//             {paymentTransaction == 0 ? <p>No history yet</p> : null}
//           </div>
//           {paymentTransaction != null && (
//             <PaginationComponent
//               currentPage={currentPage}
//               totalPages={totalPages}
//               onPageChange={handlePagination}
//             />
//           )}
//         </section>
//       </section>
//     </>
//   );
// };

// export default FundHistory;

import React, { useEffect, useState } from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { ApiPaths } from "../../Config/ApiPaths";
import Loader from "../../Components/Loader/Loader";
import PaginationComponent from "./../../Components/PaginationControls/PaginationControls";
import useAxiosHelper from "../../Common/AxiosHalper";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import "./FundHistory.css"; // Import the custom CSS file
import { toastFailed, toastSuccess } from "../../Config/BasicInfo";
import DepositSuccessfull from "../../Components/DepositSuccessfull/DepositSuccessfull";

const FundHistory = ({ status }) => {
  const [filterVisiblity, setFilterVisiblity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentTransaction, setPaymentTransaction] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const handleClosePopup = () => setPopupVisible(false);

  const { AxiosGet, AxiosPost } = useAxiosHelper();

  useEffect(() => {
    FetchData(currentPage);
  }, [status, currentPage]);

  const fundRequest = async (id) => {
    console.log(id, "..id");
    try {
      setLoading(true);
      const body = {
        id,
      };
      console.log(body, "bodyhhoihio");
      const res = await AxiosPost(ApiPaths.fundRequest, body);
      if (res) {
        toastSuccess(res?.message);
        setPopupVisible(true);
      }
    } catch (e) {
      console.error("Error fetching UPI details:", e);
      toastFailed(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const FetchData = async (page) => {
    try {
      setLoading(true);
      const filters = {
        source: "WealthFX",
      };
      const queryParams = {
        page: page,
        limit: 20,
        ...filters,
      };
      const response = await AxiosGet(
        `${ApiPaths.getPaymentTransaction}?${new URLSearchParams(
          queryParams
        ).toString()}`
      );
      setPaymentTransaction(response?.data || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error) {
      console.error("Error fetching payment transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetData = () => {
    setStartDate("");
    setEndDate("");
    FetchData();
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {loading && <Loader />}

      <section className="fund-history-section">
        {/* <div className="filter-section">
          <button onClick={() => setFilterVisiblity(!filterVisiblity)}>
            {filterVisiblity ? "Hide Filters" : "Show Filters"}
            <MdOutlineFilterList />
          </button>
        </div>
        {filterVisiblity && (
          <section className="filter-input-section">
            <Row>
              <Col lg="2" md="4" xs="12">
                <input
                  type="date"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col lg="2" md="4" xs="12">
                <input
                  type="date"
                  placeholder="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col lg="2" md="4" xs="6">
                <button onClick={() => FetchData(currentPage)}>Search</button>
              </Col>
              <Col lg="2" md="4" xs="6">
                <button onClick={resetData}>Reset</button>
              </Col>
            </Row>
          </section>
        )} */}

        <section className="history-table-section">
          <h1 className="history-heading">Deposit History</h1>
          <div className="history-table-wrapper">
            <table className="history-table">
              <thead>
                <tr>
                  <th>Sr. no</th>
                  <th>Date</th>
                  {/* <th>Deposit Amount</th> */}
                  <th>Amount</th>
                  <th>Request Amount</th>
                  <th>Payment Mode</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paymentTransaction.length > 0 ? (
                  paymentTransaction.map((x, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{moment(x?.updatedAt).format("DD MMM YYYY")}</td>
                      <td>{parseFloat(x?.amount).toFixed(2)}</td>
                      <td>{x?.requestAmount}</td>
                      <td>{x?.paymentMode}</td>
                      <td
                        className={
                          x?.status == "0"
                            ? "pending"
                            : x?.status == "1"
                            ? "approved"
                            : "rejected"
                        }
                      >
                        {x?.status == "0"
                          ? "pending"
                          : x?.status == "1"
                          ? "approved"
                          : "rejected"}
                      </td>
                      <td>
                        {x?.status == "0" ? (
                          <button
                            onClick={() => fundRequest(x?.tx_Id)}
                            className="status-btn"
                          >
                            Get Confirmed
                          </button>
                        ) : x?.status == "1" ? (
                          <p className="status-btn">Confirmed</p>
                        ) : (
                          <p className="status-btn">Expired</p>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No history yet</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {paymentTransaction.length > 20 && (
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePagination}
            />
          )}
        </section>
        <DepositSuccessfull
          isVisible={isPopupVisible}
          onClose={handleClosePopup}
        />
      </section>
    </>
  );
};

export default FundHistory;
