import React, { useEffect, useState, useMemo } from 'react';
import './ProviderGames.css';
import { Container } from 'react-bootstrap';
import { TbListDetails } from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicInfo } from '../../Config/BasicInfo';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
import { useDispatch, useSelector } from 'react-redux';
import { setLoginDisplay } from '../../Redux/LoginSlice';
import Loader from '../../Components/Loader/Loader';
import ImageLoader from '../../Components/ImageLoader/ImageLoader';
import LazyLoad from 'react-lazyload';
// import evoLobby from './../../Assets/Images/evoLobby.jpg'
const ProviderGames = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { AxiosPost } = useAxiosHelper();
    const { providerName } = useParams();
    const authToken = useSelector((state) => state.userStatus.authToken);
    const [providerGames, setProviderGames] = useState([]);
    const [loading, setLoading] = useState(false);

    // Define the target g_uid
    const targetGUid = "7054b26056f6464bde31e4fac1ce744ecd5b8e7b";

    useEffect(() => {
        FetchGames();
    }, [providerName]);

    async function FetchGames() {
        try {
            const body1 = { provider: providerName, count: 100 };
            const [gameRes1] = await Promise.all([AxiosPost(ApiPaths.getAllGames, body1)]);

            // Apply sorting only if providerName is "Evolution"
            if (providerName === "Evolution") {
                const sortedGames = gameRes1.sort((a, b) => {
                    if (a.g_uid === targetGUid) return -1;
                    if (b.g_uid === targetGUid) return 1;
                    return 0;
                });
                setProviderGames(sortedGames);
            } else {
                setProviderGames(gameRes1);
            }
        } catch (e) {
            console.error("Error fetching data", e);
        }
    }

    const getGame = async (data) => {
        let userData;
        const cachedData = localStorage.getItem('userProfile');
        if (authToken && cachedData) {
            userData = await JSON.parse(cachedData);
            try {
                setLoading(true);
                const body = {
                    game_uuid: data?.g_uid,
                    username: userData?.username,
                    name: userData?.name,
                    currency: "INR",
                };
                const res = await AxiosPost("/game_init", body);
                let link = res?.data?.url;
                setLoading(false);
                navigate('/games', { state: { url: link } });
            } catch (error) {
                console.error("Error initializing game", error);
                setLoading(false);
            }
        } else {
            dispatch(setLoginDisplay(true));
        }
    };

    const renderGames = useMemo(() => providerGames?.length > 0 ? providerGames?.map((x, i) => (
        <div onClick={() => getGame(x)} className='allGameThumbnail' key={i}>
            <LazyLoad>
                <img
                    src={x?.image || BasicInfo.DefaultGameBanner}
                    loading='lazy'
                    alt=""
                    onError={(e) => { e.target.src = BasicInfo.DefaultGameBanner; }}
                />
            </LazyLoad>
        </div>
    )) : Array.from({ length: 12 }).map((_, i) => {
        return <div key={i} id='providerSkeleton'>
            <ImageLoader />
        </div>
    }), [getGame, providerGames]);

    return (
        <section className="mainContent">
            {
                loading && <Loader />
            }
            <div id="profileHeaderHeading">
                <i><TbListDetails /></i>
                <h1>{providerName}</h1>
            </div>
            <Container className='pt-4'>
                <div className='providerGameSectionsGames'>
                    {renderGames}
                </div>
            </Container>
        </section>
    );
};

export default React.memo(ProviderGames);
