import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import './PaymentWithBank.css';
import { CiBank } from "react-icons/ci";
import HDFC from './../../Assets/Payment/hdfc.png';
import { useNavigate } from 'react-router-dom';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
const PaymentWithBank = () => {
    const { AxiosGet } = useAxiosHelper();
    const navigate = useNavigate();
    const [bankDetailsData, setBankDetailsData] = useState({});
    const [fetchDone, setFetchDone] = useState(false);
    useEffect(() => {
        if (!fetchDone) {
            FetchBankDetails();
            setFetchDone(true);
        }
    }, [fetchDone]);

    const FetchBankDetails = async () => {
        try {
            const tempBankDetailsData = await AxiosGet(ApiPaths.getPaymentMethod);
            setBankDetailsData(tempBankDetailsData?.activeOptions?.manual?.bank?.[0] || {});
        } catch (error) {
            console.error("Error fetching bank details:", error);
        } finally {

        }
    };
    return (
        <>
            <section className='mainContent pt-4'>
                <div className='paymentWithBankContent'>
                    {/* <img src={HDFC} alt="HDFC" id='paymentWithBankImage' /> */}
                    <div className="bankitems">
                        <label>Bank Name</label>
                        <div className='bankItemsInput'>
                            <input
                                value={bankDetailsData?.bankName}
                                readOnly={true}
                            />
                            <i><CiBank /></i>
                        </div>
                    </div>
                    <div className="bankitems">
                        <label>IFSC Code</label>
                        <div className='bankItemsInput'>
                            <input
                                value={bankDetailsData?.ifsc}
                                readOnly={true}
                            />
                            <i><CiBank /></i>
                        </div>
                    </div>
                    <div className="bankitems">
                        <label>Account Number</label>
                        <div className='bankItemsInput'>
                            <input
                                value={bankDetailsData?.accountNumber}
                                readOnly={true}
                            />
                            <i><CiBank /></i>
                        </div>
                    </div>
                    <div className="bankitems">
                        <label>Account Holder Name</label>
                        <div className='bankItemsInput'>
                            <input
                                value={bankDetailsData?.holder}
                                readOnly={true}
                            />
                            <i><CiBank /></i>
                        </div>
                    </div>
                    <div className="bankitems">
                        <label>Account Type</label>
                        <div className='bankItemsInput'>
                            <input
                                value={bankDetailsData?.ac_type}
                                readOnly={true}
                            />
                            <i><CiBank /></i>
                        </div>
                    </div>
                    <div className="bankitems">
                        <label>Bank Branch</label>
                        <div className='bankItemsInput'>
                            <input
                                value={bankDetailsData?.branch}
                                readOnly={true}
                            />
                            <i><CiBank /></i>
                        </div>
                    </div>

                    <h5 >If you have completed your payment. Please share proof here</h5>

                    <button className="btnPrimary" onClick={() => navigate('/payment-proof')}>Submit</button>
                </div>
            </section>
        </>
    );
};

export default PaymentWithBank;
