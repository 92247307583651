import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FiCopy } from "react-icons/fi";
import QRCode from "react-qr-code";
import RotateLoader from "./../../Components/RotateLoader/RotateLoader";
import CopyFromtag from "../../Common/CopyFromtag";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PaymentWithUSDT.css";
import { ApiPaths } from "../../Config/ApiPaths";
import useAxiosHelper from "../../Common/AxiosHalper";
import DepositSuccessfull from "../DepositSuccessfull/DepositSuccessfull";
import { toastFailed, toastSuccess } from "../../Config/BasicInfo";
import Timer from "../../Components/Time/Time";
import moment from "moment";

const PaymentWithUSDT = () => {
  const { AxiosGet, AxiosPost } = useAxiosHelper();
  const [upiDetailsData, setUpiDetailsData] = useState({});
  const location = useLocation(); // Get the location object
  const [payableAmount, setPayableAmount] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("amount") || 0; // Initialize payableAmount with the amount or default to 0
  });
  const [initialTime, setInitialTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const handleClosePopup = () => setPopupVisible(false);

  var x = 0;
  useEffect(() => {
    if (x == 0) {
      FetchWebDetails();
      // console.log(initialTime,"ooooooooooooooooooooooooooo")
      x++;
    }
  }, [payableAmount != null]);
  const fundRequest = async () => {
    try {
      setLoading(true);
      const body = {
        id: transactionId,
      };
      console.log(body, "bodyhhoihio");
      const res = await AxiosPost(ApiPaths.fundRequest, body);
      if (res) {
        toastSuccess(res?.message);
        setPopupVisible(true);
      }
    } catch (e) {
      console.error("Error fetching UPI details:", e);
      toastFailed(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  const FetchWebDetails = async () => {
    const data = localStorage.getItem("companyData");
    console.log(JSON.parse(data));
    setCompanyData(JSON.parse(data));
    try {
      setLoading(true);
      const body = {
        amount: payableAmount,
      };
      console.log(body, "Amount in body");
      const res = await AxiosPost(ApiPaths.getPaymentMode, body);
      console.log(
        moment(res?.result?.expiry_date).format("mm:ss"),
        "expiry Time"
      );
      if (res) {
        toastSuccess(res?.message);
        setUpiDetailsData(res?.payTo);
        setTransactionId(res?.result?.tx_Id);
        const formattedTime = new Date(res?.result?.time).toLocaleTimeString(
          "en-IN",
          { hour12: false, timeZone: "Asia/Kolkata" }
        );
        console.log(formattedTime, "format time initil");
        setInitialTime(formattedTime);
      }
    } catch (e) {
      console.error("Error fetching UPI details:", e);
      toastFailed(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const [companyData, setCompanyData] = useState([]);

  return (
    <section className="mainContent pt-4">
      <div className="container d-flex  align-items-center min-vh-100 flex-column">
        <div className="d-flex justify-content-between align-items-center mb-4 mt-4 w-100 px-3">
          <h2 className="text-white">Payment Page</h2>
        </div>
        <div
          className="card p-4 shadow-lg"
          style={{ width: "100%", maxWidth: "1200px" }}
        >
          <div className="d-flex flex-column gap-3 mb-4">
            <div className="d-flex justify-content-between text-white border rounded py-2 px-3 custom-border">
              <span>Network:</span>
              <span>BEP - 20</span>
            </div>
            <div className="d-flex justify-content-between text-white border rounded py-2 px-3 custom-border">
              <span>Network Chain:</span>
              <span style={{ textAlign: "right" }}>BSC</span>
            </div>
            <div className="d-flex justify-content-between text-white border rounded py-2 px-3 custom-border">
              <span>Amount to pay:</span>
              <span>{parseFloat(payableAmount / 90).toFixed(2)} USDT</span>
            </div>
            <p className="mt-2">
              {" "}
              Note: Please pay the exact amount which you have requested.
            </p>
          </div>

          <div className=" mb-4 qrCode">
            {loading ? (
              <RotateLoader />
            ) : upiDetailsData ? (
              <div id="qrCodeBG">
                <QRCode
                  size={180}
                  value={upiDetailsData}
                />
              </div>
            ) : null}
            {/* <p className="text-light mb-2">{upiDetailsData?.chain || "USDT - TRC20"}</p>
          <p className="text-muted">Don't use the same QR code to pay multiple times</p> */}
            <div style={{ color: "var(--blueColor" }}>
              <h1>End Time</h1>
              <Timer initialTime={initialTime} />{" "}
              {/* Countdown from 60 seconds */}
            </div>
          </div>

          <div className="input-group mb-4 addressContainer">
            <div>
              {" "}
              <span>Address</span>
            </div>{" "}
            <div className="addressBar">
              <h1
                className="form-control bg-dark text-white"
                id="addressInput"
              >{` ${upiDetailsData}`}</h1>
              <i
                className="btn btn-outline-light"
                onClick={() => {
                  CopyFromtag("addressInput");
                  toastSuccess("Address Copied");
                }}
              >
                <FiCopy />
                {/* Copy */}
              </i>
            </div>
          </div>

          <button
            className="btn btn-primary w-100 submitBtn"
            // onClick={() =>
            //   navigate("/dashboard/payment-proof", {
            //     state: { myData: JSON.stringify(payableAmount) },
            //   })
            // }
            onClick={fundRequest}
          >
            Click here after Payment
          </button>
          <DepositSuccessfull
            isVisible={isPopupVisible}
            onClose={handleClosePopup}
          />
        </div>
      </div>
    </section>
  );
};

export default PaymentWithUSDT;
