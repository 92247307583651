import React, { useEffect, useState } from 'react'
import useAxiosHelper from '../../Common/AxiosHalper';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiPaths } from '../../Config/ApiPaths';
import { BasicInfo, toastFailed, toastSuccess } from '../../Config/BasicInfo';
import BEP20 from "./../../Assets/Payment/Tether-usdt.png";
import RotateLoader from '../../Components/RotateLoader/RotateLoader';
import { useDispatch } from 'react-redux';
import { setMainWallet } from '../../Redux/ProfileDataSlice';

const WithdrawMainPage = () => {
    const dispatch = useDispatch();
    const { AxiosPost, AxiosGet } = useAxiosHelper();
    const [formAmount, setFormAmount] = useState("");
    const [bankData, setBankData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formAddress, setFormAddress] = useState('');
    const handleChange = (e) => {
        setFormAddress(e.target.value);
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const imageUrl = queryParams.get('url');

    useEffect(() => {
        WithdrawData();
    }, [])

    const FetchWallet = async () => {
        try {
            setLoading(true);
            const tempWalletData = await AxiosGet(ApiPaths.getWallets);
            dispatch(setMainWallet(tempWalletData?.wallets?.[0]?.value));
        } catch (error) {
            BasicInfo.isDebug &&
                console.error("Error fetching wallet details:", error);
        } finally {
            setLoading(false);
        }
    };


    const WithdrawData = async () => {
        try {
            setLoading(true);
            const queryParams = {
                limit: 20,
            };
            const tempWithdrawData = await AxiosGet(
                `${ApiPaths.getAccountDetails}?${new URLSearchParams(
                    queryParams
                ).toString()}`
            );
            console.log("tempWithdrawData", tempWithdrawData);
            setBankData(tempWithdrawData?.data);
        } catch (error) {
            BasicInfo.isDebug &&
                console.error("Error fetching wallet details:", error);
            toastFailed(error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };
    async function Withdraw() {
        if (formAmount > 0) {
            setLoading(true)
            try {
                const body = {
                    paymentMethod: type == "BEP20" || type == "TRC20" ? "web3" : type,
                    amount: formAmount,
                    account: formAddress,
                    wallet_name: "main_wallet",
                    currency: "usdt"
                };
                console.log(body);

                const res = await AxiosPost(ApiPaths.withdraw, body);
                console.log(res);
                if (res) {
                    toastSuccess(res?.message);
                    setFormAmount("");
                    FetchWallet()
                }
            } catch (error) {
                console.log(error);
                toastFailed(error?.response?.data?.message);
            } finally {
                setLoading(false);
            }
        } else {
            toastFailed("Invalid Amount");
        }
    }
    return (
        <div className="mainContent">
            <section className="withdrawalPage">
                <div>
                    {
                        type == "BEP20" ?
                            <img className='d-block m-auto' style={{ width: "100px" }} src={BEP20} alt="" />
                            :
                            <img className='d-block m-auto' style={{ width: "150px" }} src={imageUrl} alt="" />
                    }

                    {type == "bank" ? (
                        <div style={{ position: "relative" }}>
                            <label htmlFor="upiId">Bank Account Number</label>
                            <div style={{ marginBottom: "10px" }} className="incomeSelectBox">
                                <select
                                    style={{ width: "100%" }}
                                    id="upiSelect"
                                    value={formAddress}
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value="">Select Address</option>
                                    {bankData?.map((x, index) => (
                                        x?.bank?.accountNumber && <option key={index} value={x?.bank?.accountNumber}>
                                            {x?.bank?.accountNumber}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    ) : type === "upi" ? (
                        <div style={{ position: "relative" }}>
                            <label htmlFor="upiId">UPI ID</label>
                            <div style={{ marginBottom: "10px" }} className="incomeSelectBox">
                                <select
                                    style={{ width: "100%" }}
                                    id="upiSelect"
                                    value={formAddress}
                                    onChange={(e) => setFormAddress(e.target.value)}
                                >
                                    <option value="">Select Address</option>
                                    {bankData?.map((x, index) => (
                                        x?.upi?.upiId && <option key={index} value={x?.upi?.upiId}>
                                            {x?.upi?.upiId}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    ) : (
                        <div style={{ position: "relative" }}>
                            <label htmlFor="upiId">Web Address</label>
                            <div style={{ marginBottom: "10px" }} className="incomeSelectBox">
                                <select
                                    style={{ width: "100%" }}
                                    id="upiSelect"
                                    value={formAddress}
                                    onChange={(e) => setFormAddress(e.target.value)}
                                >
                                    <option value="">Select Address</option>
                                    {bankData?.map((x, index) => (
                                        x?.web3?.address && x?.web3?.chain.toLowerCase() == type.toLowerCase() ? <option key={index} value={x?.web3?.address}>
                                            {x?.web3?.address}
                                        </option> : null
                                    ))}
                                </select>
                            </div>
                        </div>
                    )}

                    <label htmlFor="amount">Amount</label>
                    <input
                        id="amount"
                        type="number"
                        className="mainInput"
                        placeholder="Enter Amount"
                        value={formAmount}
                        onChange={(e) => {
                            setFormAmount(e.target.value);
                        }}
                        required
                    />
                    {
                        type == "BEP20" && <p className='mt-2' style={{ fontSize: "14px" }}>You will get = {parseFloat(formAmount / 90).toFixed(2)} USDT</p>
                    }
                    {
                        loading ? <div className="mt-4"><RotateLoader /></div> :
                            <button className="btnPrimary" onClick={Withdraw}>
                                Withdraw
                            </button>
                    }
                </div>
            </section>
        </div>
    )
}

export default WithdrawMainPage