import React from 'react';
import './Footer.css';
import Facebook from './../../Assets/Images/facebook.png'
import twitter from './../../Assets/Images/twitter.png'
import instagram from './../../Assets/Images/instagram.png'
import { BasicInfo } from '../../Config/BasicInfo';
import { Col, Row } from 'react-bootstrap';
import Adult from './../../Assets/Images/adult.png';
import { useNavigate } from 'react-router-dom';
import paytm from "./../../Assets/Payment/paytm.svg";
import gpay from "./../../Assets/Payment/GPay.svg";
import phnpe from "./../../Assets/Payment/phonepe.svg";
import Bank from "./../../Assets/Payment/bank.png";
import TRC20 from "./../../Assets/Payment/Tether-USD-TRC20-token(1).svg";
import BEP20 from "./../../Assets/Payment/Tether-usdt.png";
const Footer = () => {
    const navigate = useNavigate();
    const paymentData = [
        { logo: paytm, height: "100%", width: "100%", type: "upi" },
        { logo: gpay, height: "100%", width: "100%", type: "upi" },
        { logo: phnpe, height: "100%", width: "100%", type: "upi" },
        { logo: Bank, height: "60%", width: "60%", type: "bank" },
        { logo: TRC20, height: "100%", width: "100%", type: "TRC20" },
        { logo: BEP20, height: "60%", width: "60%", type: "BEP20" },
    ];

    return (
        <section className='myFooter'>
            <Row>
                <Col lg="4">
                    <div className="footersLinks">
                        <a onClick={() => navigate('/policies/gamepolicy')}>Game Policy</a>
                        <a onClick={() => navigate('/policies/privacypolicy')}>Privacy Policy</a>
                        <a onClick={() => navigate('/policies/faq')}>FAQ</a>
                        <a onClick={() => navigate('/policies/terms')}>Terms and conditions</a>
                        <a>News</a>
                        <a>Responsible Gaming Policy</a>
                        <a>Bonus Terms</a>
                        <a>Rules for Specific sports</a>
                        <a>AML/KYC Policy</a>
                        <a>Affiliate program</a>
                        <a>Gamepitara Odds & Predictions</a>
                    </div>
                </Col>
                <Col lg="6" className='d-flex justify-content-start' id="adultLogoDown">
                    <div>
                        <div className="footerPaymentMethods">
                            {paymentData.map((x, index) => (
                                <div key={index}>
                                    <img
                                        src={x.logo}
                                        style={{ width: x.width, height: x.height }}
                                        alt={x.type}
                                    />
                                </div>
                            ))}
                        </div>
                        <p id='copyRightLine'>Copyright © 2018 {BasicInfo.projectName}. All rights are reserved and protected by law</p>
                    </div>
                </Col>
                <Col lg="2" id='footerAdultLogo'>
                    <img src={Adult} alt="18+" id='adult' className='m-0' />
                    <div className="footerSocialMedia">
                        <img src={Facebook} alt="Facebook" />
                        <img src={twitter} alt="X" />
                        <img src={instagram} alt="Instagram" />
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default Footer