import React, { useEffect, useState } from "react";
import "./Fund.css";
import { Row, Col } from "react-bootstrap";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ApiPaths } from "../../Config/ApiPaths";
import { AiOutlineUser } from "react-icons/ai";
import useAxiosHelper from "../../Common/AxiosHalper";
import { setUserPersonalInfo } from "../../Redux/ProfileDataSlice";
import { setIncomeWallet } from "../../Redux/IncomeWallet";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import FundHistory from "./FundHistory";

const Fund = () => {
  const dispatch = useDispatch();
  const { BigInt } = window;
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [showFundHistory, setShowFundHistory] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [paymentTransaction, setPaymentTransaction] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [status, setStatus] = useState("0");
  const { AxiosGet } = useAxiosHelper();
  const [paymentMode, setPaymentMode] = useState();
  const handleDeposit = () => {
    if (amount > 0) {
      const data = { amount: amount };
      const queryParams = new URLSearchParams(data).toString();
      window.location.href = `payment-bep20?${queryParams}`;
    } else {
      toastFailed("Invalid amount");
    }
  };

  const [companyData, setCompanyData] = useState([]);
  useEffect(() => {
    CompanyInfo();
  }, []);
  async function CompanyInfo() {
    try {
      const data = localStorage.getItem("companyData");
      BasicInfo.isDebug && console.log(JSON.parse(data));
      setCompanyData(JSON.parse(data));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <section className="mainContent pt-4">
        <section className="dashboard">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            <div className="planDiv" style={{ background: "none" }}>
              <h1>Fund Deposit</h1>
              <div className="paymentContainer">
                <div>
                  <label htmlFor="Amount">Amount</label>
                  <input
                    type="number"
                    className="inputPrimary"
                    placeholder="Enter Amount"
                    style={{ padding: "8px 20px" }}
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <p className="errorMsg">{amountError}</p>
                </div>
                <div>
                  <label htmlFor="paymentMode">Payment Mode</label>{" "}
                  <select
                    id="paymentMode"
                    className="inputPrimary"
                    style={{ padding: "8px 20px" }}
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    {/* <option value="" disabled>
                    Select Payment Mode
                  </option> */}
                    <option value="USDT BEP-20">USDT BEP-20</option>
                    {/* <option value="USDT ERC-20">USDT ERC-20</option> */}
                    {/* <option value="BTC">BTC</option> */}
                    {/* <option value="ETH">ETH</option> */}
                    {/* <option value="USD">USD</option> */}
                  </select>
                  {/* <p className="errorMsg">{paymentModeError}</p> */}
                </div>
              </div>
              <button className="btnPrimary w-auto " onClick={handleDeposit}>
                Submit
              </button>
              {/* <div className="addfundDiv inputPrimary ">

                    </div> */}
            </div>
          </div>

          <section className="fund-history">
            {status && <FundHistory status={status} />}
          </section>
        </section>
      </section>
    </>
  );
};

export default Fund;
