import React, { useEffect, useState } from 'react';
import './PaymentWithUPI.css';
import { useNavigate } from 'react-router-dom';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
import { BsQrCodeScan } from "react-icons/bs";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { FaReceipt } from "react-icons/fa";
import RotateLoader from '../RotateLoader/RotateLoader';
import { QRCodeSVG } from 'qrcode.react';
import { IoCopyOutline } from 'react-icons/io5';
import CopyFromtag from '../../Common/CopyFromtag';
const PaymentWithUPI = () => {
    const { AxiosGet } = useAxiosHelper();
    const navigate = useNavigate();
    const [upiDetailsData, setUpiDetailsData] = useState({});
    const [payableAmount, setPayableAmount] = useState();
    const [fetchDone, setFetchDone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    useEffect(() => {
        if (!fetchDone) {
            FetchBankDetails();
            setFetchDone(true);
        }
    }, [fetchDone]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const amount = urlParams.get('amount');
        setPayableAmount(amount)
    }, [])


    const FetchBankDetails = async () => {
        try {
            setLoading(true)
            const tempBankDetailsData = await AxiosGet(ApiPaths.getPaymentMethod);
            setUpiDetailsData(tempBankDetailsData?.activeOptions?.manual?.upi?.[0] || {});
        } catch (error) {
            console.error("Error fetching upi details:", error);
        } finally {
            setLoading(false);
        }
    };
    function handlleSubmit() {
        const queryParams = new URLSearchParams({ amount: payableAmount }).toString();
        navigate(`/payment-proof?${queryParams}`);
    }
    const UpiQrCode = ({ upiId, amount, name }) => {
        // Generate UPI payment link
        const upiLink = `upi://pay?pa=${upiId}&pn=${name}&mc=&tid=&tt=&am=${amount}&cu=INR&url=`;

        return (
            <div>
                <QRCodeSVG
                    value={upiLink}
                    size={256}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="Q"
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                />
            </div>
        );
    };
    function copyUPI() {
        setIsCopied(true);
        CopyFromtag("copyUpiId")
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    }
    return (
        <>
            <section className='mainContent pt-4'>
                <div className='paymentWithUPIContent'>
                    <div id='paymentWithUpiAmountDiv'>
                        <p>Amount</p>
                        <p>₹{payableAmount}</p>
                    </div>

                    <div id='paymentWithUPIContentQRDiv'>
                        <h1>Scan QR Code To Pay</h1>
                        {
                            loading == true ? <RotateLoader /> :
                                upiDetailsData?.upiId != null ? <div id='upiQRCodeMain'>
                                    <UpiQrCode upiId={upiDetailsData?.upiId} amount={payableAmount} name={upiDetailsData?.name} />
                                </div> : ""
                        }
                        <p style={{ color: "var(--textHeading)", margin: "0", marginTop: "10px" }}>{upiDetailsData?.name}</p>
                        <div id='upiAddressDiv' className='mt-2'>
                            <p style={{ color: "var(--textHeading)", margin: "0" }} id='copyUpiId'>{upiDetailsData?.upiId}</p>
                            {
                                upiDetailsData?.upiId &&
                                (isCopied ? <p style={{ margin: "0", color: "#fff", fontSize: "10px" }}>Copied</p> :
                                    <i onClick={copyUPI}><IoCopyOutline /></i>)
                            }
                        </div>
                        <p>Don't use the same QR code to pay multiple times</p>
                    </div>

                    <div id='paymentWithUPIContentProcess'>
                        <div>
                            <i><BsQrCodeScan /></i>
                            <p>Scan the QR code or copy the provided UPI ID.</p>
                        </div>
                        <div>
                            <i><FaMoneyCheckDollar /></i>
                            <p>Open your payment app (PayTM, PhonePE, GooglePay, BHIM, etc.) and select the "Send Money" or "UPI Payment" option.</p>
                        </div>
                        <div>
                            <i style={{ fontSize: "22px" }}><FaReceipt /></i>
                            <p>Enter the payment amount. Then, review the payment details and complete the transaction.</p>
                        </div>
                    </div>
                    <h5 >If you have completed your payment. Please share proof here</h5>

                    <button className="btnPrimary" onClick={handlleSubmit}>Submit</button>
                </div>
            </section>
        </>
    );
};

export default PaymentWithUPI;
