import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { BasicInfo } from "../../Config/BasicInfo";
import { useSelector, useDispatch } from "react-redux";
import { setLoginDisplay } from "../../Redux/LoginSlice";
import { setRegisterDisplay } from "../../Redux/RegisterSlice";
import Rupee from "./../../Assets/Images/rupee.svg";
import { useNavigate } from "react-router-dom";
import { IoIosArrowUp } from "react-icons/io";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { setAuthToken } from "../../Redux/StatusState";
import PaymentMethod from "../PaymentMethod/PaymentMethod";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import RotateLoader from "../RotateLoader/RotateLoader";
import { setMainWallet } from "../../Redux/ProfileDataSlice";
import { RiMenu3Fill } from "react-icons/ri";
import { LiaTimesSolid } from "react-icons/lia";
import { IoNotifications } from "react-icons/io5";

const Header = () => {
  const { AxiosGet } = useAxiosHelper();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.userStatus.authToken);
  const walletBalance = useSelector((state) => state.profileData.mainWallet);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [paymentShow, setPaymentShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchDone, setFetchDone] = useState(false);
  const ws = useRef(null); // Using useRef to store the WebSocket instance
  useEffect(() => {
    if (true) {
      FetchWallet();
      setFetchDone(true);
    }
  }, [fetchDone, authToken]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // useEffect(() => {
  //   const addGoogleTranslateScript = () => {
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.async = true;
  //     script.src =
  //       "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
  //     document.body.appendChild(script);
  //   };

  //   // Initialize Google Translate if the script isn't already loaded
  //   if (!window.google) {
  //     addGoogleTranslateScript();
  //   }

  //   // Function to initialize the Google Translate widget
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement(
  //       { pageLanguage: "en" },
  //       "google_translate_element"
  //     );
  //   };
  // }, []);

  const Logout = () => {
    handleClose();
    BasicInfo.isDebug && console.log("working");
    dispatch(setAuthToken(null));
    localStorage.removeItem("token");
    localStorage.removeItem("userProfile");
    navigate("/");
  };
  const Dashboard = () => {
    navigate("/dashboardProfile");
    handleClose();
  };

  const Bids = () => {
    navigate("/bets");
    handleClose();
  };
  const Profile = () => {
    navigate("/profile");
    handleClose();
  };
  const PaymentHistory = () => {
    navigate("./history-deposit");
    handleClose();
  };
  const Incomes = () => {
    navigate("/incomes");
    handleClose();
  };
  const Team = () => {
    navigate("/direct-team");
    handleClose();
  };
  const Withdrawal = () => {
    navigate("/withdrawal");
    handleClose();
  };
  const Referral = () => {
    navigate("/referral");
    handleClose();
  };
  const Account = () => {
    navigate("/account-details");
    handleClose();
  };
  function changeVisiblity() {
    setPaymentShow(!paymentShow);
  }
  let x = 0;
  const FetchWallet = async () => {
    try {
      setLoading(true);
      const tempWalletData = await AxiosGet(ApiPaths.getWallets);
      dispatch(setMainWallet(tempWalletData?.wallets?.[0]?.value));
    } catch (error) {
      BasicInfo.isDebug &&
        console.error("Error fetching wallet details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ws.current = new WebSocket("https://apis.gamepitara.com"); // Ensure this matches your server URL
    let userData = localStorage.getItem("userProfile");
    var parsedData;
    if (userData) {
      parsedData = JSON.parse(userData);
    }
    BasicInfo.isDebug && console.log("userProfile", parsedData);
    ws.current.onopen = () => {
      BasicInfo.isDebug && console.log("Connected to the server");
      ws.current.send(JSON.stringify({ userId: parsedData?.username }));
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      dispatch(setMainWallet(message?.balance));
      BasicInfo.isDebug && console.log(message);
      // setMessages((prevMessages) => [...prevMessages, message.balance]);
    };

    ws.current.onclose = () => {
      BasicInfo.isDebug && console.log("Disconnected from the server");
    };

    ws.current.onerror = (error) => {
      BasicInfo.isDebug && console.error("WebSocket error:", error);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  return (
    <>
      {paymentShow && <PaymentMethod fun={changeVisiblity} />}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem></MenuItem>

        <MenuItem onClick={Dashboard}>Dashboard</MenuItem>
        <MenuItem onClick={Referral}>Referral</MenuItem>

        <MenuItem onClick={Profile}>Profile</MenuItem>
        <MenuItem onClick={PaymentHistory}>Payments History</MenuItem>
        <MenuItem onClick={Bids}>Bids</MenuItem>
        <MenuItem onClick={Withdrawal}>Withdrawal</MenuItem>
        <MenuItem onClick={Account}>Account Details</MenuItem>

        <MenuItem onClick={Team}>Team</MenuItem>
        <MenuItem onClick={Incomes}>Incomes</MenuItem>
        <MenuItem onClick={Logout}>Logout</MenuItem>
      </Menu>
      <section className="header">
        <div className="headerLogo" onClick={() => navigate("/")}>
          <img src={BasicInfo.logo} alt="" />
        </div>
        <div className="headerLogoIcon" onClick={() => navigate("/")}>
          <img src={BasicInfo.logoIcon} alt="" />
        </div>
        <div className="headerItems">
          <ul>
            <li onClick={() => navigate("/")}>MAIN</li>
            <li onClick={() => navigate("/provider/Ezugi")}>Ezugi</li>
            <li onClick={() => navigate("/provider/Evolution")}>Evolution</li>
            <li onClick={() => navigate("/provider/Vivogaming")}>Vivogaming</li>
            <li onClick={() => navigate("/provider/Spribe")}>Spribe</li>
            {/* <li>Casino</li> */}
            <li>Live Dealers</li>
            {/* <li>TV Games</li> */}
            <li>Bonuses</li>
            <li>Cricket</li>
            <li>Sports</li>
            {/* <div
              id="google_translate_element"
              style={{ margin: "10px 0" }}
            ></div> */}
          </ul>
        </div>
        {authToken != null ? (
          <div className="headerDepositAndBalance">
            {/* <div className='headerDepositAndBalanceItem'>
                                <button className="btnPrimary" onClick={changeVisiblity} style={{ padding: "8px 12px", boxShadow: "0px 0px 15px 0px var(--blueColor)" }}>Deposit</button>
                            </div> */}
            <div className="headerDepositAndBalanceItem">
              <div id="headerDepositAndBalanceItemInner">
                <div id="headerBalance">
                  <img src={Rupee} alt="bal" />
                  {/* <h1>+</h1> */}
                  {loading == true ? (
                    <RotateLoader />
                  ) : (
                    <p>{parseFloat(walletBalance ?? 0).toFixed(2)}</p>
                  )}
                </div>
                <i style={{ color: "var(--lightYellow" }}>
                  <IoNotifications />
                </i>

                <button
                  className="btnPrimary"
                  id="depositButton"
                  onClick={changeVisiblity}
                  style={{ padding: "8px 12px" }}
                >
                  Deposit
                </button>
              </div>
              {anchorEl ? (
                <i id="menuIcon" onClick={handleClick}>
                  <LiaTimesSolid />
                </i>
              ) : (
                <i id="menuIcon" onClick={handleClick}>
                  <RiMenu3Fill />
                </i>
              )}
            </div>
          </div>
        ) : (
          <div className="headerLoginArea">
            <button
              id="bntPrimary"
              onClick={() => dispatch(setLoginDisplay(true))}
            >
              SIGN IN
            </button>
            <button
              id="bntSecondary"
              onClick={() => dispatch(setRegisterDisplay(true))}
            >
              SIGN UP
            </button>
          </div>
        )}
      </section>
    </>
  );
};

export default Header;
