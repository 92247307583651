// import React from "react";
// import CrossButton from "../../Image/CrossButton.svg";
// import "./DepositSuccessfull.css"; // Import your CSS file for styling

// const DepositSuccessfull = ({ isVisible, onClose }) => {
//   if (!isVisible) return null;

//   return (
//     <div className="popup-overlay">
//       <div className="popup-content">
//         <h2>Deposit Successful!</h2>
//         <button className="popup-close" onClick={onClose}>
//           <img src={CrossButton} alt="" />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default DepositSuccessfull;

import React from "react";
import { useNavigate } from "react-router-dom";
import CrossButton from "../../Assets/Images/CrossButton.svg";
import ConfirmTick from "../../Assets/Images/ConfirmTick.svg";
import "./DepositSuccessfull.css"; // Import your CSS file for styling

const DepositSuccessfull = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    // Navigate to the dashboard
    navigate("/");
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Deposit Successful!</h2>
        <button className="popup-close" onClick={onClose}>
          <img src={CrossButton} alt="Close" />
        </button>
        <button className="popup-confirm-button" onClick={handleConfirm}>
          <img src={ConfirmTick} alt="Confirm" />
          Confirm
        </button>
      </div>
    </div>
  );
};

export default DepositSuccessfull;
