import React, { useEffect, useState } from 'react'
import './DepositHistory.css';
import { Col, Row } from 'react-bootstrap';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../../Components/PaginationControls/PaginationControls';
import Loader from '../../Components/Loader/Loader';
import ProfileHeader from '../../Components/ProfileHeader/ProfileHeader';
const DepositHistory = () => {
    const navigate = useNavigate();
    const [paymentTransaction, setPaymentTransaction] = useState();
    const [totalPages, setTotalPages] = useState();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(false);

    const { AxiosGet } = useAxiosHelper();
    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    var x = 0;

    useEffect(() => {
        if (x === 0) {
            FetchData()
            x++;
        }
    }, [])

    const FetchData = async (page) => {
        try {
            setLoading(true);
            const filters = {
                tx_type: "add_fund"
            };
            const queryParams = {
                page: page,
                limit: 20,
                ...filters
            };
            const response = await AxiosGet(`${ApiPaths.getPaymentTransaction}?${new URLSearchParams(queryParams).toString()}`);
            setPaymentTransaction(response?.data || []);
            setTotalPages(response?.totalPages || 1);
        } catch (error) {
            console.error("Error fetching payment transactions:", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <section className="mainContent">
                {
                    loading && <Loader />
                }
                <ProfileHeader />
                <section className="paymentHistoryTabs">
                    <div>
                        <h1 className='activePaymentHistoryTab' onClick={() => navigate('/history-deposit')}>Deposit History</h1>
                        <h1 onClick={() => navigate('/history-withdraw')}>Withdrawal History</h1>
                    </div>
                </section>

                <section className='paymentHistoryBody'>
                    <Row>
                        {
                            paymentTransaction != null && Array.isArray(paymentTransaction) ?

                                paymentTransaction?.map((x, i) => {
                                    return <Col md="6" lg="4" className='m-0 p-1'>
                                        <div className="depositHistoryCard">
                                            <div id='depositHistoryCardTop'>
                                                <p>{x?.createdAt}</p>
                                                {
                                                    x?.status == 0 ?
                                                        <h5 style={{ background: "var(--blueColor)" }}>Pending</h5> :
                                                        x?.status == 1 ?
                                                            <h5 style={{ background: "green" }}>Success</h5> :
                                                            <h5>Failed</h5>
                                                }
                                            </div>
                                            <h1 id='depositHistoryCardHeading'>{x?.amount} INR</h1>
                                            <div id='depositHistoryCardDetails'>
                                                <p>Transaction ID</p>
                                                <p>{x?.reqest_tx_Id}</p>
                                            </div>
                                        </div>
                                    </Col>
                                }) : ""

                        }
                    </Row>
                </section>
                {
                    paymentTransaction != null &&
                    <PaginationComponent currentPage={currentPage} totalPages={totalPages} onPageChange={handlePagination} />
                }
            </section>

        </>
    )
}

export default DepositHistory