import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ApiPaths } from "../../Config/ApiPaths";
import { toastFailed, toastSuccess } from "../../Config/BasicInfo";
import useAxiosHelper from "../../Common/AxiosHalper";
import Select from "react-select";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import { BasicInfo } from "../../Config/BasicInfo";
const WebDetails = () => {
  const [name, setName] = useState('bep20');
  const [upiId, setUpiId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("null");
  const [upiData, setUpiData] = useState([]);
  const [defaultUpiId, setDefaultUpiId] = useState(null);
  const { AxiosGet, AxiosPost } = useAxiosHelper();
  const navigate = useNavigate();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--containerColor)",
      borderColor: "var(--containerColor)",
      padding: "4px",
      fontSize: "16px",
      borderRadius: "30px",
      marginBottom: "20px",
      color: "#fff",
    }),
  };
  var x = 0;
  useEffect(() => {
    if (x == 0) {
      fetchWebData();
      x++;
    }
  }, []);

  const fetchWebData = async () => {
    try {
      setLoading(true);
      const response = await AxiosGet(ApiPaths.getWebDetails);
      BasicInfo.isDebug && console.log(response, "upi details");
      if (response) {
        setUpiData(response?.web3Details);
        const defaultUpi = response?.web3Details.find(
          (address) => address.isDefault
        );
        setDefaultUpiId(defaultUpi?.address || null);
        toastSuccess(response?.message);
      }
    } catch (error) {
      console.log(error);
      toastFailed(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        chain: name,
        address: upiId,
      };
      console.log("body111111111111111111", body)
      const addupidetails = await AxiosPost(ApiPaths.addWebDetails, body);
      BasicInfo.isDebug && console.log(addupidetails);
      if (addupidetails) {
        fetchWebData();
        toastSuccess(addupidetails?.message);
        setName("");
        setUpiId("");
      }
    } catch (error) {
      console.log(error);
      toastFailed(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (upiId) => {
    try {
      setLoading(true);
      const body = {
        address: upiId,
      };
      BasicInfo.isDebug && console.log(body);

      const deleteupidetails = await AxiosPost(ApiPaths.deleteWebDetails, body);
      BasicInfo.isDebug && console.log(deleteupidetails);
      if (deleteupidetails) {
        fetchWebData();
        toastSuccess(deleteupidetails?.message);
      }
    } catch (error) {
      toastFailed(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDefault = async (upiId) => {
    try {
      setLoading(true);
      const body = {
        address: upiId,
      };
      console.log(body);
      const defaultupidetails = await AxiosPost(
        ApiPaths.defaultWebDetails,
        body
      );
      console.log(defaultupidetails);
      if (defaultupidetails) {
        fetchWebData();
        toastSuccess(defaultupidetails?.message);
      }
    } catch (error) {
      console.log(error);
      toastFailed(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="mainContent">
      <ProfileHeader />
      <section className="incomeContent">
        <section className="paymentHistoryTabs">
          <div>
            <h1 onClick={() => navigate("/account-details")}>Bank </h1>
            <h1 onClick={() => navigate("/upi-details")}>UPI</h1>
            <h1
              className="activePaymentHistoryTab"
              onClick={() => navigate("/web-details")}
            >
              WEB
            </h1>
          </div>
        </section>
        <section id="upiForm">
          <div
            style={{ marginTop: "5%" }}
            className="d-flex justify-content-center"
          >
            <h2 className="heading">WEB3 Form</h2>
          </div>
          <section id="formContent">
            <div className="form">
              <Row>
                <Col lg={6} style={{ margin: "auto" }}>
                  <form onSubmit={handleSubmit} className="registerForm">
                    <div id="formFields">
                      <div className="mb-3">
                        <label htmlFor="name">Network</label>
                        <div className="mb-3 myProfileInputField">
                          {/* <input
                            type="number"
                            id="name"
                            className="form-control"
                            value={name}
                            placeholder="Enter your chain"
                            onChange={(e) => setName(e.target.value)}
                            required
                          /> */}
                          <select value={name} onChange={(e) => setName(e.target.value)}>
                            <option value="bep20">BEP20</option>
                            <option value="trc20">TRC20</option>
                          </select>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="upiId">Address</label>
                        <div className="mb-3 myProfileInputField">
                          <input
                            type="text"
                            id="upiId"
                            className="form-control"
                            value={upiId}
                            placeholder="Enter your address"
                            onChange={(e) => setUpiId(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          setName("");
                          setUpiId("");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </section>

          <section className="history">
            <div className="d-flex gap-2"></div>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Chain</th>
                    <th>Address</th>
                    <th>Delete</th>
                    <th>Set Default</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(upiData) &&
                    upiData?.map((x, i) => (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{x?.chain}</td>
                        <td className="text-center">{x?.address}</td>
                        <td className="text-center">
                          <button
                            className="block-btn"
                            onClick={() => handleDelete(x?.address)}
                          >
                            Delete Web3
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className={`block-btn ${defaultUpiId === x?.address ? "default-btn" : ""
                              }`}
                            onClick={() => handleDefault(x?.address)}
                            disabled={defaultUpiId === x?.address}
                          >
                            {defaultUpiId === x?.address
                              ? "Default"
                              : "Set Default"}
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default WebDetails;
