import { createSlice } from '@reduxjs/toolkit';

export const statusSlice = createSlice({
  name: 'status',
  initialState: {
    authToken: null,
    user: null,
    walletAddress: null,
    // Add more status fields as needed
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload; // Corrected field name to authToken
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    // Add more reducers for other status fields
  },
});

export const { setAuthToken, setUser, setWalletAddress } = statusSlice.actions;
export default statusSlice.reducer;
