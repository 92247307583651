import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ApiPaths } from '../../Config/ApiPaths';
import useAxiosHelper from '../../Common/AxiosHalper';
import { useParams } from 'react-router-dom';
import Footer from '../../Components/Footer/Footer';
import Loader from '../../Components/Loader/Loader';
import { useSelector } from 'react-redux';
import Login from '../Login/Login';
import Register from '../Register/Register';
import { BasicInfo } from '../../Config/BasicInfo';

const Policy = () => {
    const { AxiosGet } = useAxiosHelper();
    const [documentData, setDocumentData] = useState();
    const { policyName } = useParams();
    const [loading, setLoading] = useState(false);
    const isShowLogin = useSelector((state) => state.loginDisplay.value);
    const isShowRegister = useSelector((state) => state.registerDisplay.value);
    useEffect(() => {
        FetchData();
    }, [policyName]);

    const FetchData = async () => {
        try {
            setLoading(true);
            const tempPaymentTransaction = await AxiosGet(`${ApiPaths.getDocumentation}?title=${policyName}`);
            BasicInfo.isDebug && console.log("tempPaymentTransaction", tempPaymentTransaction);
            setDocumentData(tempPaymentTransaction);
        } catch (e) {
            console.error("e", e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="mainContent p-2" style={{ wordBreak: "break-word" }}>
                {loading && <Loader />}
                {isShowRegister && <Register />}
                {isShowLogin && <Login />}
                <Container>
                    <div dangerouslySetInnerHTML={{ __html: documentData }} />
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default Policy;
