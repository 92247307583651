import React, { useEffect, useState } from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { ApiPaths } from "./../../Config/ApiPaths";
import Loader from "./../../Components/Loader/Loader";
import "./GenerationTeam.css";
import PaginationComponent from "../../Components/PaginationControls/PaginationControls";
import useAxiosHelper from "./../../Common/AxiosHalper";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import { useNavigate } from "react-router-dom";

const GenerationTeam = () => {
  const [selectLevel, setSelectLevel] = useState([]);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterUserId, setFilterUserId] = useState("");
  const [generationTeam, setGenerationTeam] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterJoinDate, setFilterJoinDate] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const { AxiosGet } = useAxiosHelper();
  const navigate = useNavigate();
  const [level, setLevel] = useState("1");
  const [levelNumber, setLevelNumber] = useState("1");
  useEffect(() => {
    getLevels();
  }, []);

  const fetchIncome = (e) => {
    setLevel(e.target.value);
    // Assuming format "Level X"
    const levelNumber = e.target.value.split(" ")[1];
    setLevelNumber(levelNumber);
    console.log(levelNumber);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const getLevels = async () => {
    try {
      const response = await AxiosGet(ApiPaths.getPackages);
      const levels = response?.packages?.[0]?.level_income?.level;

      setSelectLevel(levels);
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
  };
  useEffect(() => {
    FetchData();
  }, [levelNumber]);
  const FetchData = async (page) => {
    try {
      const queryParams = {
        page: page,
        limit: 20,
        levels: levelNumber,
      };
      console.log(queryParams);
      setLoading(true);
      const response = await AxiosGet(
        `${ApiPaths.getTeams}?${new URLSearchParams(queryParams).toString()}`
      );
      setGenerationTeam(response?.data || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error) {
      console.error("Error fetching payment transactions:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterReset = () => {
    setFilterUserId("");
    setFilterJoinDate("");
    setFilterStatus("");
    FetchData(1);
  };

  return (
    <section className="mainContent">
      {loading && <Loader />}
      <ProfileHeader />
      <section className="paymentHistoryTabs">
        <div>
          <h1 onClick={() => navigate("/direct-team")}>Direct Team</h1>
          <h1
            className="activePaymentHistoryTab"
            onClick={() => navigate("/generation-team")}
          >
            Generation Team
          </h1>
        </div>
      </section>
      <section className="incomeContent">
        <div className="incomeSelectBox">
          <select value={level} onChange={(e) => fetchIncome(e)}>
            {Array.isArray(selectLevel) &&
              selectLevel.map((x, index) => (
                <option key={index} value={x[index]}>{`Level ${index + 1
                  }`}</option>
              ))}
          </select>
        </div>
        <section className="history">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>User Id</th>
                  <th>Name</th>
                  <th>Join Date</th>
                  <th>Activation Date</th>
                  <th>Sponsor ID (Name)</th>
                  <th>Self Business</th>
                  <th>Team Business</th>
                </tr>
              </thead>
              <tbody>
                {generationTeam.map((x, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{x?.username}</td>
                    <td>{x?.name}</td>
                    <td>{x.joining_date}</td>
                    <td>{x?.Activation_date}</td>
                    <td>
                      {x?.sponsor_username} ({x?.sponsor_Name})
                    </td>
                    <td>{x?.self_investment}</td>
                    <td>{x?.business}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {generationTeam.length === 0 && <p>No history yet</p>}
          </div>
          {generationTeam.length > 0 && (
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePagination}
            />
          )}
        </section>
      </section>
    </section>
  );
};

export default GenerationTeam;
