import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ApiPaths } from "../../Config/ApiPaths";
import useAxiosHelper from "../../Common/AxiosHalper";
import "./../../Common.css";
import { IoCopy } from "react-icons/io5";
import { BasicInfo } from "../../Config/BasicInfo";
import { setIncomeWallet } from "./../../Redux/IncomeWallet";
import { useDispatch, useSelector } from "react-redux";
import { setUserPersonalInfo } from "../../Redux/ProfileDataSlice";
import CopyFromtag from "../../Common/CopyFromtag";
import ReferImg from "./../../Assets/Images/refer.png";
import "./Dashboard.css";
import Loader from "../../Components/Loader/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { AxiosGet } = useAxiosHelper();
  const incomeData = useSelector((state) => state.incomeData.incomeWallet);
  const profileData = useSelector(
    (state) => state.profileData.userPersonalInfo
  );
  const [loading, setLaoding] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = (id) => {
    CopyFromtag(id);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset copied state after 2 seconds
  };
  var x = 0;
  useEffect(() => {
    if (x === 0) {
      fetchData();
      FetchProfile();
      x++;
    }
  }, []);

  async function fetchData() {
    try {
      const res = await AxiosGet(ApiPaths.getWallets);
      BasicInfo.isDebug && console.log(res);
      if (res) dispatch(setIncomeWallet(res?.wallets));
    } catch (error) {
      console.log(error);
    }
  }

  const FetchProfile = async () => {
    if (profileData == null || profileData?.length == 0) {
      try {
        setLaoding(true);
        const tempProfileData = await AxiosGet(ApiPaths.getProfile);
        BasicInfo.isDebug && console.log("tempProfileData", tempProfileData);
        dispatch(setUserPersonalInfo(tempProfileData));
      } catch (e) {
        console.error("e", e);
      } finally {
        setLaoding(false);
      }
    }
  };
  return (
    <section className="myProfile">
      {loading && <Loader />}
      <div className="iconHeadingAndLink">
        <div className="iconHeading">
          <p>Refer And Earn</p>
        </div>
      </div>
      <section className="referAndEarn">
        <Row style={{ width: "100%" }}>
          <Col md="6">
            <div className="referAndEarnLeft">
              <img src={ReferImg} alt="refer" />
            </div>
          </Col>
          <Col md="6" className="p-0">
            <div className="referAndEarnRight">
              <p>Your referral Link</p>
              <div>
                <p id="referLinkCopy">{`https://gamepitara.com?ref=${profileData?.username}`}</p>

                <div onClick={() => handleCopy("referLinkCopy")}>
                  <i>
                    <IoCopy />
                  </i>
                  {isCopied ? (
                    <p className="copied-animation">Copied!</p>
                  ) : (
                    <p>Copy</p>
                  )}
                </div>
              </div>
              <h5>
                Share your referral link with Your <br /> frinds and get
                benifits.
              </h5>
            </div>
          </Col>
        </Row>
      </section>
      <hr />
      <section>
        <Row md="12">
          <Col
            lg="12"
            className="gap-2 d-flex flex-column justify-content-between"
          >
            <Row>
              {incomeData?.map((x, i) => {
                return (
                  x?.wallet_type == "income" && (
                    <Col md="3" className="mb-2">
                      <div className="dashboardIncomeCard">
                        <div className="dashboardData">
                          <div>
                            <h5
                              className="dashboardCardHeading"
                              style={{ textTransform: "capitalize" }}
                            >
                              {x?.name}
                            </h5>
                            <h1>₹ {parseFloat(x?.value).toFixed(2) ?? "0"}</h1>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                );
              })}
            </Row>
          </Col>
        </Row>
      </section>
    </section>
  );
};
export default Dashboard;
