import React from 'react';
import './Header.css';
import { MdCasino, MdGamepad, MdOutlineCasino } from "react-icons/md";
import { IoGameControllerSharp, IoGiftSharp } from 'react-icons/io5';
import { FaPlaneDeparture } from 'react-icons/fa';
import Live from './../../Assets/animatedIcons/live.gif'
import Aviator from './../../Assets/animatedIcons/aviator.gif'
import Bonus from './../../Assets/animatedIcons/bonus.gif'
import Card from './../../Assets/animatedIcons/card.gif';
import Casino from './../../Assets/animatedIcons/casino.gif';
import { useNavigate } from 'react-router-dom';
const MobileHeader = () => {

    const navigate = useNavigate();


    const mobileHeaderData = [
        // { title: 'main', icon: <MdGamepad />, url: "" },
        // { title: 'live', icon: <IoGameControllerSharp />, url: "" },
        // { title: 'casino', icon: <MdOutlineCasino />, url: "" },
        // { title: 'live casino', icon: <MdCasino />, url: "" },
        // { title: 'aviator', icon: <FaPlaneDeparture />, url: "" },
        // { title: 'bonuses', icon: <IoGiftSharp />, url: "" }

        { title: 'main', icon: <MdGamepad />, url: { Live }, navigateLink: "/" },
        { title: 'ezugi', icon: null, url: Live, navigateLink: "/provider/Ezugi" },
        { title: 'casino', icon: null, url: Casino, navigateLink: "/provider/Evolution" },
        { title: 'Vivogaming', icon: null, url: Card, navigateLink: "/provider/Vivogaming" },
        { title: 'spribe', icon: null, url: Aviator, color: "#E60052", navigateLink: "/provider/Spribe" },
        { title: 'bonuses', icon: null, url: Bonus }
    ]

    return (
        <section className="mobileHeader">
            {
                mobileHeaderData?.map((x, i) => {
                    return <div onClick={() => x?.navigateLink ? navigate(x?.navigateLink) : null}>
                        {
                            x?.icon ?
                                <i>{x?.icon}</i>
                                :
                                <img src={x?.url} alt='' />
                        }
                        <p style={{ color: x.color }}>{x?.title}</p>
                    </div>
                })
            }
        </section>
    )
}

export default MobileHeader