import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Gift from "./../../Assets/Images/gift.png";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import { Link, Navigate, useNavigate } from "react-router-dom";
import BannerImage from "./../../Assets/Images/registerImg.jpg";
import { useDispatch } from "react-redux";
import { setLoginDisplay } from "../../Redux/LoginSlice";
import { setRegisterDisplay } from "../../Redux/RegisterSlice";
import { ApiPaths } from "../../Config/ApiPaths";
import RotateLoader from "../../Components/RotateLoader/RotateLoader";
import { toast } from "react-toastify";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import { setAuthToken } from "../../Redux/StatusState";
import useAxiosHelper from "../../Common/AxiosHalper";
import Adult from "./../../Assets/Images/adult.png";
import { RiLockPasswordFill } from "react-icons/ri";
import { setForgotDisplay } from "../../Redux/Forgot";
import OtpInput from "react-otp-input";
import "./Forgot.css";

// import "./Login.css";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AxiosPost } = useAxiosHelper();

  // const toastSuccess = (msg) => toast.success(msg);
  // const toastFailed = (msg) => toast.error(msg);
  const [formUsername, setFormUsername] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isShowOTP, setIsShowOTP] = useState(false);
  const [otp, setOtp] = useState();
  const [passwordVisiblity, setPasswordVisiblity] = useState(false);

  //   const options = [
  //     { value: "1", label: "Sport Welcome Pack 700%" },
  //     { value: "2", label: "Crash Welcome Pack 700%" },
  //     { value: "3", label: "Play Without Bonus" },
  //   ];

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        SendOtp();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [formUsername, formPassword]);

  async function SendOtp() {
    if (formUsername?.length > 0) {
      try {
        setIsLoading(true);

        const body = {
          username: formUsername,
          action: "forgot_password",
        };
        BasicInfo.isDebug && console.log(body, "...");

        const res = await AxiosPost(ApiPaths.sendOtp, body);
        BasicInfo.isDebug && console.log("send OTP", res);
        if (res) {
          setIsShowOTP(true);
          toastSuccess(res?.message);
          dispatch(setAuthToken(res?.token));
          localStorage.setItem("token", res?.token);
          localStorage.setItem("userProfile", JSON.stringify(res?.user));
        }
      } catch (error) {
        console.log(error);
        // toastFailed(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      toastFailed("Invalid Data");
    }
  }
  async function VerifyOtp() {
    if (formUsername?.length > 0 && formPassword.length > 0) {
      setIsLoading(true);
      try {
        const body = {
          username: formUsername,
          newPassword: formPassword,
          otp: otp,
          action: "forgot_password",
        };
        BasicInfo.isDebug && console.log(body, "...");
        const response = await AxiosPost(ApiPaths.forgotPassword, body);
        BasicInfo.isDebug && console.log("send OTP", response);
        if (response) {
          toastSuccess(response?.message);
          dispatch(setLoginDisplay(true));
          // dispatch(setForgotDisplay(false));
        }
      } catch (error) {
        console.log(error);
        toastFailed(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    }
  }
  return (
    <>
      <section className="registerSection">
        <div className="registerData" id="loginSection">
          <div className="registerLeft">
            <img src={BannerImage} alt="banner" />
            <div className="registerLeftContent">
              <h1>Welcome Back</h1>
              <p>On the best innovate sportsbook and casino</p>
              <img src={BasicInfo.logo} alt="Logo" />
            </div>
          </div>
          <div className="registerRight">
            <div
              style={{
                position: "relative",
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <i
                className="d-flex align-items-center justify-content-end mb-2"
                id="registerCrossIcon"
                onClick={() => dispatch(setForgotDisplay(false))}
              >
                <RxCross2 />
              </i>
              <div id="registerGiftDiv " className="mb-3 mt-4">
                <div id="registerGiftDivIcon">
                  <div>
                    <i style={{ fontSize: "30px" }}>
                      <RiLockPasswordFill />
                    </i>{" "}
                  </div>
                  <p>Forgot Password</p>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  top: "15%",
                }}
              >
                <div>
                  <input
                    type="text"
                    placeholder="Enter your username"
                    className="mainInput mb-12"
                    value={formUsername}
                    onChange={(e) => setFormUsername(e.target.value)}
                    required
                  />
                  {isShowOTP ? (
                    <div className="registerOtp">
                      <input
                        type="text"
                        placeholder="Set new Password"
                        className="mainInput mb-12"
                        value={formPassword}
                        onChange={(e) => setFormPassword(e.target.value)}
                        required
                      />

                      <div
                        style={{
                          height: "170px",
                          border: "none",
                          alignSelf: "center",
                          zIndex: "1",
                        }}
                      >
                        <div>
                          <h4
                            style={{
                              color: "White",
                              fontSize: 20,
                            }}
                          >
                            Enter OTP
                          </h4>
                        </div>
                        <div>
                          <div className="otpInputBox">
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              renderSeparator={<span>-</span>}
                              containerStyle={{ width: "100%" }}
                              renderInput={(props) => (
                                <input type="number" {...props} />
                              )}
                            />
                            <div
                              style={{
                                display: "flex",
                                marginTop: "20px",
                                gap: "10px",
                              }}
                            >
                              <button
                                className="btnPrimary"
                                onClick={VerifyOtp} // Assuming VerifyOtp is the function to verify OTP
                              >
                                Verify OTP
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {" "}
                      {/* <div className="alreadyRegister mb-2">
                        <p>Don't have an account?</p>
                        <Link
                          onClick={() => dispatch(setRegisterDisplay(true))}
                        >
                          Sign up
                        </Link>
                      </div> */}
                      <div style={{ textAlign: "center" }}>
                        {isLoading && <RotateLoader />}{" "}
                        {/* Display Loader when loading is true */}
                        <button
                          onClick={SendOtp}
                          className="block-btn"
                          style={{ fontSize: "14px" }}
                          disabled={isLoading} // Disable button while loading
                        >
                          {isLoading ? "Sending..." : "Send OTP"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
