import React from 'react';

const SomeComponent = () => {
  return (
    <div style={{ height: '500px', width: '500px', border: '1px solid black' }}>
      <iframe
        src="https://pamm.thewealthfx.com/iframe"
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Embedded Webpage"
      />
    </div>
  );
};

export default SomeComponent;
