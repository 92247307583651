import React, { useEffect, useState } from 'react'
import "./Register.css";
import { RxCross2 } from 'react-icons/rx';
import Gift from "./../../Assets/Images/gift.png";
import IndiaFlag from "./../../Assets/Images/germany.png";
import { IoIosArrowDown } from 'react-icons/io';
import { IoEyeOffSharp, IoEyeSharp } from 'react-icons/io5';
import { HiPlusSm } from 'react-icons/hi';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import BannerImage from "./../../Assets/Images/registerImg.jpg"
import { useSelector, useDispatch } from 'react-redux'
import { setRegisterDisplay } from '../../Redux/RegisterSlice';
import { setLoginDisplay } from '../../Redux/LoginSlice';
import { BasicInfo, toastFailed, toastSuccess } from '../../Config/BasicInfo';
import RotateLoader from '../../Components/RotateLoader/RotateLoader';
import { ApiPaths } from '../../Config/ApiPaths';
import useAxiosHelper from '../../Common/AxiosHalper';
import { setAuthToken } from '../../Redux/StatusState';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import Adult from './../../Assets/Images/adult.png';

const Register = () => {
    const { AxiosPost } = useAxiosHelper();
    const dispatch = useDispatch();
    const [passwordVisiblity, setPasswordVisiblity] = useState(false);
    const [havePromoCode, setHavePromoCode] = useState(false);
    const [formName, setFormName] = useState("");
    const [formEmail, setFormEmail] = useState("");
    const [formMobile, setFormMobile] = useState("")
    const [formNameError, setFormNameError] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [formEmailError, setFormEmailError] = useState("");
    const [formMobileError, setFormMobileError] = useState("")
    const [formPasswordError, setFormPasswordError] = useState("");
    const [formPromoCode, setFormPromoCode] = useState("");
    const [confirmTerms, setConfirmTerms] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [sponsor, setSponsor] = useState("");
    const options = [
        { value: '1', label: 'Sport Welcome Pack 700%' },
        { value: '2', label: 'Crash Welcome Pack 700%' },
        { value: '3', label: 'Play Without Bonus' },
    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--containerColor)',
            borderColor: 'var(--containerColor)',
            padding: "4px",
            fontSize: '16px',
            borderRadius: "30px",
            marginBottom: "20px"
        })
    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const ref = params.get('ref');
        console.log("ref", ref)
        if (ref) {
            setSponsor(ref);
        }
    }, []);

    const [selectedOption, setSelectedOption] = useState(null);
    function ChnageFun() {
        dispatch(setRegisterDisplay(false))
        dispatch(setLoginDisplay(true))
    }

    async function RegisterFun() {

        const isFormValid = await checkFormValid();
        if (isFormValid == true) {
            setIsLoading(true);
            try {
                const body = {
                    "name": formName,
                    "email": formEmail,
                    "mobile": formMobile,
                    "password": formPassword,
                    "sponsor": sponsor
                }
                const res = await AxiosPost(ApiPaths.register, body);
                BasicInfo.isDebug && console.log("register", res);
                if (res.status == 200) {
                    toastSuccess(res?.message);
                    localStorage.setItem('token', res?.token);
                    dispatch(setRegisterDisplay(false));
                    dispatch(setAuthToken(res?.token));
                    localStorage.setItem('userProfile', JSON.stringify(res?.User));
                }
                setIsLoading(false);
            } catch (error) {
                console.error(error)
                toastFailed(error?.response?.data?.message);
                setIsLoading(false);
            }
        }
    }

    async function checkFormValid() {
        setFormNameError("");
        setFormEmailError("");
        setFormMobileError("");
        setFormPasswordError("");

        let x = 0;
        if (formName == null || formName.length == 0) {
            setFormNameError("Name is required");
        } else {
            x++;
        }
        if (formEmail == null || formEmail.length == 0) {
            setFormEmailError("Email is required")
        } else {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const emailVarify = regex.test(formEmail);
            if (emailVarify == true) {
                x++;
            } else {
                setFormEmail("E-mail must be valid");
            }
        }
        if (formMobile == null || formMobile.length == 0) {
            setFormMobileError("Phone number must be valid");
        } else if (!isValidPhoneNumber(formMobile)) {
            setFormMobileError("Invalid phone number");
        } else {
            x++;
        }

        if (formPassword.length >= 6) {
            x++
        } else {
            setFormPasswordError("min 6 characters")
        }
        if (x == 4) {
            return true;
        } else return false;
    }

    return (
        <section className="registerSection">
            <div className="registerData">
                <div className="registerLeft">
                    <img src={BannerImage} alt="banner" />
                    <div className="registerLeftContent">
                        <h1>SIGN UP</h1>
                        <p>On the best innovate sportsbook and casino</p>
                        <img src={BasicInfo.logo} alt="Logo" />
                    </div>
                </div>
                <div className="registerRight">
                    <div style={{ position: "relative" }}>
                        <i className='d-flex align-items-center justify-content-end mb-2' id='registerCrossIcon' onClick={() => dispatch(setRegisterDisplay(false))}><RxCross2 /></i>
                        <div id="registerGiftDiv " className='mb-3 mt-4'>
                            <div id="registerGiftDivIcon">
                                <div>
                                    <img src={Gift} alt="gift" />
                                </div>
                                <p>
                                    10000 INR ON FIRST <br /> DEPOSIT
                                </p>
                            </div>
                        </div>
                        <input type="text" placeholder='Name' className='mainInput' value={formName} onChange={(e) => setFormName(e.target.value)} />
                        <p className='formError'>{formNameError}</p>
                        <input type="text" placeholder='Email' className='mainInput mt-12' value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
                        <p className='formError'>{formEmailError}</p>
                        <div id='registerMobileDiv' className='mt-12'>
                            <PhoneInput
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="US"
                                value={formMobile}
                                onChange={setFormMobile}
                                error={formMobile ? (isValidPhoneNumber(formMobile) ? undefined : 'Invalid phone number') : 'Phone number required'} />

                            {/* <input type="text" placeholder='Phone number' className='mainInput' value={formMobile} onChange={(e) => setFormMobile(e.target.value)} /> */}
                        </div>
                        <p className='formError'>{formMobileError}</p>
                        <div id='registerPasswordDiv' className='  mt-12'>
                            <input type={passwordVisiblity == true ? "text" : "password"} placeholder='Password' className='mainInput' value={formPassword} onChange={(e) => setFormPassword(e.target.value)} />
                            <i onClick={() => setPasswordVisiblity(!passwordVisiblity)}>
                                {
                                    passwordVisiblity == true ? <IoEyeSharp /> : <IoEyeOffSharp />
                                }</i>
                        </div>
                        <p className='formError'>{formPasswordError}</p>
                        {/* {
                            havePromoCode == true ?
                                <div className="registerApplyCode mt-12">
                                    <input type="text" placeholder='Add promo code here' className='mainInput' value={formPromoCode} onChange={(e) => setFormPromoCode(e.target.value)} />
                                    <button style={{ background: formPromoCode ? "#4992DB" : "rgba(255, 255, 255, .12)" }}>Apply</button>
                                </div>
                                : <div id="registerPromoCode" className='mt-12' onClick={() => setHavePromoCode(true)}>
                                    <i><HiPlusSm /></i>
                                    <p>I have a promo code</p>
                                </div>
                        }
                        <p className='modal__form-title'>Choose your bonus</p>
                        <Select
                            isClearable={true}
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            styles={customStyles} // Apply custom styles here
                        /> */}
                        {
                            isLoading == true ? <RotateLoader /> :
                                <button className='btnPrimary mt-12' onClick={RegisterFun} style={{ fontSize: "14px" }}>SIGN UP</button>
                        }

                        <label for="topping1" className='registerTermCondition' style={{ color: confirmTerms != true && "red" }}>
                            <input type="checkbox" id="topping1" checked={confirmTerms} onChange={(e) => setConfirmTerms(e.target.checked)} />
                            i confirm all the <a href='#' style={{ color: confirmTerms != true && "red" }}>Terms of user agreement</a> and that i am over 18
                        </label>
                        <img src={Adult} alt="18+" id="adult" />

                        <div className='alreadyRegister'>
                            <p>Already registered?</p>
                            <Link onClick={ChnageFun}>Sign in</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Register