import React, { useState, useEffect } from "react";

const Timer = ({ initialTime }) => {
  // Convert HH:MM:SS to seconds
  const convertTimeToSeconds = (timeString) => {
    if (!timeString) return 0; // Handle cases where timeString is undefined or null
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const [timeLeft, setTimeLeft] = useState(0); // Initialize state to 0

  useEffect(() => {
    if (initialTime) {
      // Convert and set timeLeft only when initialTime is available
      setTimeLeft(convertTimeToSeconds(initialTime));
    }
  }, [initialTime]); // Re-run whenever initialTime prop changes

  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => Math.max(prevTime - 1, 0)); // Ensure timeLeft doesn't go negative
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Convert seconds to HH:MM:SS format
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hours > 0 ? hours + ":" : ""}${minutes
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  return (
    <div>
      <div>{formatTime(timeLeft)}</div>
    </div>
  );
};

export default Timer;
