import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import './Banner.css';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';

const Banner = () => {
    const { AxiosGet } = useAxiosHelper();
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        const storedBanners = localStorage.getItem('banners');
        const storedTimestamp = localStorage.getItem('bannersTimestamp');
        const expiryTime = 60 * 60 * 1000; // 1 hour

        if (storedBanners && storedTimestamp && (Date.now() - parseInt(storedTimestamp)) < expiryTime) {
            try {
                // Parse the stored banners if they are still fresh (within the expiry time)
                setBanners(JSON.parse(storedBanners));
            } catch (error) {
                console.error("Error parsing stored banners", error);
                FetchBanners(); // If there is an error parsing, fetch new banners
            }
        } else {
            // Fetch new banners if they are expired or not present
            FetchBanners();
        }
    }, []);

    async function FetchBanners() {
        try {
            const tempBanners = await AxiosGet(ApiPaths.getBanners);
            console.log("Fetched Banners", tempBanners);
            setBanners(tempBanners?.banners || []);

            // Store the banners and timestamp in localStorage
            localStorage.setItem('banners', JSON.stringify(tempBanners?.banners || []));
            localStorage.setItem('bannersTimestamp', Date.now().toString());
        } catch (error) {
            console.error("Error fetching banners", error);
        }
    }

    return (
        <Carousel autoplay loop>
            {banners?.map((banner, index) => (
                <div key={index} className='dashboardBannerImage'>
                    <img src={banner?.document} alt="banner" />
                </div>
            ))}
        </Carousel>
    );
};

export default Banner;
