import React, { useEffect, useState } from 'react';
import './Profile.css';
import { Col, Row } from 'react-bootstrap';
import { MdOutlineModeEdit } from 'react-icons/md';
import useAxiosHelper from '../../Common/AxiosHalper';
import { ApiPaths } from '../../Config/ApiPaths';
import { useSelector } from 'react-redux';
import './../../Common.css'
import { IoCopy, IoEye, IoEyeOff } from 'react-icons/io5';
import { BasicInfo, toastFailed, toastSuccess } from '../../Config/BasicInfo';
import RotateLoader from '../RotateLoader/RotateLoader';
import { useDispatch } from 'react-redux';
import { setUserPersonalInfo } from '../../Redux/ProfileDataSlice';
import ReferImg from './../../Assets/Images/refer.png'
import CopyFromtag from '../../Common/CopyFromtag';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
const Profile = () => {
    const dispatch = useDispatch();
    const { AxiosGet, AxiosPost } = useAxiosHelper();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPasswordType, setOldPasswordType] = useState("password");
    const [newPasswordType, setNewPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [passwordLoading, setPasswordLoading] = useState(false);
    const profileData = useSelector((state) => state.profileData.userPersonalInfo);
    const [loading, setLaoding] = useState(false);
    // const dashboardProviders = useSelector((state) => state.dashboardData.dashboardProviders);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = (id) => {
        CopyFromtag(id);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset copied state after 2 seconds
    };
    var x = 0;
    useEffect(() => {
        if (x === 0) {
            FetchData()
            x++;
        }
    }, [])

    const FetchData = async () => {
        if (profileData == null || profileData?.length == 0) {
            try {
                setLaoding(true)
                const tempProfileData = await AxiosGet(ApiPaths.getProfile);
                BasicInfo.isDebug && console.log("tempProfileData", tempProfileData)
                dispatch(setUserPersonalInfo(tempProfileData));
            } catch (e) {
                console.error("e", e);
            } finally {
                setLaoding(false);
            }
        }
    }
    const checkPasswordDataIsValid = () => {
        if (oldPassword?.length > 0 && newPassword?.length > 0 && confirmPassword?.length > 0) {
            if (newPassword == confirmPassword) {
                return true
            } else {
                toastFailed("new password and confirm password should be same");
                return false
            }
        } else {
            toastFailed('Invalid Data');
            return false
        }
    }
    const ChangePassword = async () => {
        let valid = checkPasswordDataIsValid();
        if (valid) {
            setPasswordLoading(true)
            try {
                const formData = {
                    currentPassword: oldPassword,
                    newPassword: newPassword
                }
                const response = await AxiosPost(ApiPaths.changePassword, formData);
                toastSuccess(response?.message);
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
            } catch (e) {
                console.error("ChangePassword", e);
                toastFailed(e?.response?.data?.message);
            } finally {
                setPasswordLoading(false);
            }
        }
    }
    return (
        <section className='mainContent'>

            <ProfileHeader />
            <section className="myProfile">
                <Row>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">Name</span>
                            <input id="myProfileInputFieldInput" type="text" placeholder='gamepitara' value={profileData?.name} readOnly={true} />
                            <i id="myProfileInputFieldIcon"><MdOutlineModeEdit /></i>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">Username</span>
                            <input id="myProfileInputFieldInput" type="text" placeholder='username' value={profileData?.username} readOnly={true} />
                            <i id="myProfileInputFieldIcon"><MdOutlineModeEdit /></i>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">E-mail</span>
                            <input id="myProfileInputFieldInput" type="text" placeholder='abc@gmail.com' value={profileData?.email} readOnly={true} />
                            <i id="myProfileInputFieldIcon"><MdOutlineModeEdit /></i>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">Phone Number</span>
                            <input id="myProfileInputFieldInput" type="text" placeholder='12345-67890' value={profileData?.mobile} readOnly={true} />
                            <i id="myProfileInputFieldIcon"><MdOutlineModeEdit /></i>
                        </div>
                    </Col>

                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">Sponsor Name</span>
                            <input id="myProfileInputFieldInput" type="text" placeholder='abc@gmail.com' value={profileData?.sponsor_Name} readOnly={true} />
                            <i id="myProfileInputFieldIcon"><MdOutlineModeEdit /></i>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">Joining Date</span>
                            <input id="myProfileInputFieldInput" type="text" placeholder='joining date' value={profileData?.joining_date} readOnly={true} />
                            <i id="myProfileInputFieldIcon"><MdOutlineModeEdit /></i>
                        </div>
                    </Col>
                </Row>
                {
                    loading === true ?
                        <button className="btnPrimaryMedium" style={{ cursor: "progress" }}><RotateLoader /></button> :
                        <button className="btnPrimaryMedium">Update Data</button>
                }
                <h3 style={{ color: "var(--textHeading)", fontWeight: "600" }}>Change Password</h3>
                <hr style={{ color: "var(--textLight)", margin: "20px 0 60px 0" }} />

                <Row>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">Old Password</span>
                            <input id="myProfileInputFieldInput" type={oldPasswordType} placeholder='Enter Old Password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                            {
                                oldPasswordType == "password" ?
                                    <i id="myProfileInputFieldIcon" onClick={() => setOldPasswordType("text")}><IoEyeOff /></i> :
                                    <i id="myProfileInputFieldIcon" onClick={() => setOldPasswordType("password")}><IoEye /></i>
                            }
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">New Password</span>
                            <input id="myProfileInputFieldInput" type={newPasswordType} placeholder='Enter New Password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            {
                                newPasswordType == "password" ?
                                    <i id="myProfileInputFieldIcon" onClick={() => setNewPasswordType("text")}><IoEyeOff /></i> :
                                    <i id="myProfileInputFieldIcon" onClick={() => setNewPasswordType("password")}><IoEye /></i>
                            }
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="myProfileInputField">
                            <span id="myProfileInputFieldTitle">Confirm Password</span>
                            <input id="myProfileInputFieldInput" type={confirmPasswordType} placeholder='Enter Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            {
                                confirmPasswordType == "password" ?
                                    <i id="myProfileInputFieldIcon" onClick={() => setConfirmPasswordType("text")}><IoEyeOff /></i> :
                                    <i id="myProfileInputFieldIcon" onClick={() => setConfirmPasswordType("password")}><IoEye /></i>
                            }
                        </div>
                    </Col>
                </Row>
                {
                    passwordLoading === true ?
                        <button className="btnPrimaryMedium" style={{ cursor: "progress" }}><RotateLoader /></button> :
                        <button className="btnPrimaryMedium" onClick={ChangePassword}> Update Password</button>
                }
                {/* <hr style={{ color: "var(--textLight)", margin: "60px 0" }} /> */}


                {/* <div className="iconHeadingAndLink">
                <div className="iconHeading">
                    <p>Refer And Earn</p>
                </div>
            </div> */}

                {/* <section className="referAndEarn">
                <Row style={{ width: "100%" }}>
                    <Col md="6">
                        <div className="referAndEarnLeft">
                            <img src={ReferImg} alt="refer" />
                        </div>
                    </Col>
                    <Col md="6" className='p-0'>
                        <div className="referAndEarnRight">
                            <p>Your referral Link</p>
                            <div>
                                <p id="referLinkCopy">{`https://gamepitara.com?ref=${profileData?.username}`}</p>

                                <div onClick={() => handleCopy("referLinkCopy")}>
                                    <i><IoCopy /></i>
                                    {isCopied ? <p className="copied-animation">Copied!</p> : <p>Copy</p>}

                                </div>
                            </div>
                            <h5>Share your referral link with Your <br /> frinds and get benifits.</h5>
                        </div>
                    </Col>
                </Row>
            </section> */}
            </section>

        </section>


    )
}

export default Profile